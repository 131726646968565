











import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private paws: any = [];
  private del(index: any) {
    const d: any = this.paws;
    d.splice(index, 1);
    localStorage.setItem("zhppaws", JSON.stringify(d));
    this.$forceUpdate();
    this.$emit("delPaw", d);
  }
  mounted() {
    if (localStorage.getItem("zhppaws")) {
      this.paws = JSON.parse(localStorage.getItem("zhppaws") || "");
    }
  }
}
