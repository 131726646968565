







import { Component, Vue, Prop } from "vue-property-decorator";
import Common from "../components/common.vue";
import Ky from "../components/ky.vue";
import Zhp from "../components/zhp.vue";
@Component({
  components: {
    Common,
    Ky,
    Zhp,
  },
})
export default class Name extends Vue {
  private get configuration() {
    return this.$store.state.configuration;
  }
}
